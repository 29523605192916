import React from 'react';
import Section from 'components/generic/Section';
import styled from 'styled-components';
import {
  gridPaddings,
  largeBody,
  smallBody,
  xsmallBody,
  navType,
  navTypeEmphasized,
  headerNavType,
  headerNavTypeEmphasized,
} from 'styles';

export default function Fonts() {
  return (
    <>
      <StyledSection>
        <Pre>h1</Pre>
        <h1>Enjoy telepresence in a small or medium size multipurpose meeting room.</h1>
      </StyledSection>

      <StyledSection>
        <Pre>h2</Pre>
        <h2>Enjoy telepresence in a small or medium size multipurpose meeting room.</h2>
      </StyledSection>

      <StyledSection>
        <Pre>h3</Pre>
        <h3>Enjoy telepresence in a small or medium size multipurpose meeting room.</h3>
      </StyledSection>

      <StyledSection>
        <Pre>h4</Pre>
        <h4>Enjoy telepresence in a small or medium size multipurpose meeting room.</h4>
      </StyledSection>

      <StyledSection>
        <Pre>h5</Pre>
        <h5>Enjoy telepresence in a small or medium size multipurpose meeting room.</h5>
      </StyledSection>

      <StyledSection>
        <Pre>h6</Pre>
        <h6>Enjoy telepresence in a small or medium size multipurpose meeting room.</h6>
      </StyledSection>

      <StyledSection>
        <Pre>Large Body</Pre>
        <LargeBody>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin consectetur in ligula varius vestibulum.
          Phasellus molestie molestie tincidunt. Proin posuere odio vel placerat mollis. Ut posuere non leo finibus
          tincidunt. Phasellus lacinia vitae tellus aliquam volutpat. Mauris lectus ipsum, suscipit quis vestibulum sit
          amet, porttitor id orci. Maecenas vel gravida ipsum. Cras mollis augue non augue venenatis cursus. Nunc
          ullamcorper dui dui, nec imperdiet neque dictum quis. Etiam at lacus nec neque posuere auctor at sit amet
          purus. Etiam vitae dictum sapien. In hac habitasse platea dictumst. Ut ornare libero vel placerat tempor.
        </LargeBody>
      </StyledSection>

      <StyledSection>
        <Pre>p (default: Medium Body)</Pre>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin consectetur in ligula varius vestibulum.
          Phasellus molestie molestie tincidunt. Proin posuere odio vel placerat mollis. Ut posuere non leo finibus
          tincidunt. Phasellus lacinia vitae tellus aliquam volutpat. Mauris lectus ipsum, suscipit quis vestibulum sit
          amet, porttitor id orci. Maecenas vel gravida ipsum. Cras mollis augue non augue venenatis cursus. Nunc
          ullamcorper dui dui, nec imperdiet neque dictum quis. Etiam at lacus nec neque posuere auctor at sit amet
          purus. Etiam vitae dictum sapien. In hac habitasse platea dictumst. Ut ornare libero vel placerat tempor.
        </p>
      </StyledSection>

      <StyledSection>
        <Pre>Small Body</Pre>
        <SmallBody>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin consectetur in ligula varius vestibulum.
          Phasellus molestie molestie tincidunt. Proin posuere odio vel placerat mollis. Ut posuere non leo finibus
          tincidunt. Phasellus lacinia vitae tellus aliquam volutpat. Mauris lectus ipsum, suscipit quis vestibulum sit
          amet, porttitor id orci. Maecenas vel gravida ipsum. Cras mollis augue non augue venenatis cursus. Nunc
          ullamcorper dui dui, nec imperdiet neque dictum quis. Etiam at lacus nec neque posuere auctor at sit amet
          purus. Etiam vitae dictum sapien. In hac habitasse platea dictumst. Ut ornare libero vel placerat tempor.
        </SmallBody>
      </StyledSection>

      <StyledSection>
        <Pre>XSmall Body</Pre>
        <XsmallBody>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin consectetur in ligula varius vestibulum.
          Phasellus molestie molestie tincidunt. Proin posuere odio vel placerat mollis. Ut posuere non leo finibus
          tincidunt. Phasellus lacinia vitae tellus aliquam volutpat. Mauris lectus ipsum, suscipit quis vestibulum sit
          amet, porttitor id orci. Maecenas vel gravida ipsum. Cras mollis augue non augue venenatis cursus. Nunc
          ullamcorper dui dui, nec imperdiet neque dictum quis. Etiam at lacus nec neque posuere auctor at sit amet
          purus. Etiam vitae dictum sapien. In hac habitasse platea dictumst. Ut ornare libero vel placerat tempor.
        </XsmallBody>
      </StyledSection>

      <StyledSection>
        <Pre>Nav Type</Pre>
        <NavType href=".">Enjoy telepresence in a small or medium size multipurpose meeting room.</NavType>
      </StyledSection>

      <StyledSection>
        <Pre>Nav Type Emphasized</Pre>
        <NavTypeEmphasized href=".">
          Enjoy telepresence in a small or medium size multipurpose meeting room.
        </NavTypeEmphasized>
      </StyledSection>

      <StyledSection>
        <Pre>Header Nav Type</Pre>
        <HeaderNavType href=".">Enjoy telepresence in a small or medium size multipurpose meeting room.</HeaderNavType>
      </StyledSection>

      <StyledSection>
        <Pre>Header Nav Type Emphasized</Pre>
        <HeaderNavTypeEmphasized href=".">
          Enjoy telepresence in a small or medium size multipurpose meeting room.
        </HeaderNavTypeEmphasized>
      </StyledSection>

      <StyledSection>
        <Pre>a</Pre>
        <a href=".">Enjoy telepresence in a small or medium size multipurpose meeting room.</a>
      </StyledSection>

      <StyledSection>
        <Pre>input</Pre>
        <p>
          <input type="text"></input>
        </p>
      </StyledSection>

      <StyledSection>
        <Pre>.label (deprecated?)</Pre>
        <p className="label">Enjoy telepresence in a small or medium size multipurpose meeting room.</p>
      </StyledSection>

      <StyledSection>
        <Pre>.muted (deprecated?)</Pre>
        <p className="muted">Enjoy telepresence in a small or medium size multipurpose meeting room.</p>
      </StyledSection>

      <StyledSection>
        <Pre>.negative (deprecated?)</Pre>
        <p className="negative" style={{ backgroundColor: 'black', padding: '10px' }}>
          Enjoy telepresence in a small or medium size multipurpose meeting room.
        </p>
      </StyledSection>

      <StyledSection>
        <Pre>button</Pre>
        <p>
          <button>This is a button</button>
        </p>
      </StyledSection>
    </>
  );
}

const StyledSection = styled(Section)`
  ${gridPaddings}
`;

const Pre = styled.pre`
  font-family: courier;
  border: solid 1px #eeeeee;
  display: block;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 5px;
  color: black;
  margin: 30px 10px 30px 0;
`;

const LargeBody = styled.p`
  ${largeBody}
`;
const SmallBody = styled.p`
  ${smallBody}
`;
const XsmallBody = styled.p`
  ${xsmallBody}
`;
const NavType = styled.a`
  ${navType}
`;
const NavTypeEmphasized = styled.a`
  ${navTypeEmphasized}
`;
const HeaderNavType = styled.a`
  ${headerNavType}
`;
const HeaderNavTypeEmphasized = styled.a`
  ${headerNavTypeEmphasized}
`;
